.headlinePrimary {
  strong { 
      background: linear-gradient(to top,  var(--primary-fade) 40%, transparent 40%);
  }
}

.gradient-bkg {
  background: linear-gradient(90.35deg, var(--primary) .31%, var(--primary-hover) 99.24%);
  position: relative;
  overflow: hidden;
  &:before, &::after {
    background: linear-gradient(45deg, rgba(256,256,256, 0.075) .31%, rgba(256,256,256, 0.0) 99.24%);
    z-index: 2;
    height: 400px;
    aspect-ratio: 1;
    border-radius: 50%;
    position: absolute;
    content: '';
  }
  &:before {
    top: -100px;
    left: -100px;
  }
  &:after {
    bottom: -100px;
    right: -100px;
  }
}

.sunset-theme {
  .gradient-bkg {
    background: radial-gradient(circle at 30% 107%, #efc57c 0%, #fdec97 5%, #fd5949 45%,#d6249f 75%,#9d28eb 100%);
  }
  .reverse-gradient {
    background: linear-gradient(45deg, rgba(125,125,125, 0.5) .31%, rgba(180,180,180, 0.5) 99.24%);
  }
}


.black-theme {
  .gradient-bkg {
    background: linear-gradient(45deg, rgba(20, 20, 20, 0.5) .31%, rgba(19, 19, 19, 0.5) 99.24%);
  }
}

.reverse-gradient {
  background: linear-gradient(90.35deg, var(--primary-hover) .31%, var(--primary) 99.24%);
}

.theme-box-shadow {
  -webkit-box-shadow: 0px 24px 27px -15px  var(--primary-fade);
  -moz-box-shadow: 0px 24px 27px -15px  var(--primary-fade);
  box-shadow: 0px 24px 27px -15px  var(--primary-fade);
  &.shadow-direction-up {
    -webkit-box-shadow: 0px 24px 27px 15px  var(--primary-fade);
    -moz-box-shadow: 0px 24px 27px 15px  var(--primary-fade);
    box-shadow: 0px 24px 27px 15px  var(--primary-fade);
  }
}

.silver-theme .theme-box-shadow {
  -webkit-box-shadow: 0px 24px 27px -15px  rgba(0,0,0,0.25);
  -moz-box-shadow: 0px 24px 27px -15px  rgba(0,0,0,0.25);
  box-shadow: 0px 24px 27px -15px  rgba(0,0,0,0.25);
  &.shadow-direction-up {
    -webkit-box-shadow: 0px 24px 27px 15px  rgba(0,0,0,0.25);
    -moz-box-shadow: 0px 24px 27px 15px  rgba(0,0,0,0.25);
    box-shadow: 0px 24px 27px 15px  rgba(0,0,0,0.25);
  }
}

.hero-bg, .background-image-featured {
  position: relative;
  width: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.parallax-container {
  height: 40vh;
  position: relative;
  overflow: hidden;
  @media only screen and (min-width: 960px)  {
    height: 95vh;
  }
  &.accent:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: -15vw;
    right: -15vw;
    z-index: 1;
    opacity: .75;
    box-shadow: inset 0 0 10vw 5vw #101010;
  } 
  .image-parallax {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @supports ( -webkit-touch-callout : none)  {
      background-attachment: scroll;
    }
    @supports not ( -webkit-touch-callout : none){
      background-attachment: fixed;
    }
  }
}

.slick-slider {
  position: relative;
  .slick-arrow {
    position: absolute !important;
    z-index: 1 !important;
  }
  .slick-dots {
    position: absolute;
    bottom: 25px;
    z-index: 1;
    li {
      &.slick-active  {
        button:before {
        color: var(--primary);
        }
      }
      button:before {
        color: var(--white);
      }
    } 
  }
}
.slider-image {
  height: 40vh;
  object-fit: cover;
  @media only screen and (min-width: 960px) {
    height: 95vh;
  }
}


// .album-swiper {
//   .swiper {
//     width: 240px !important;
//     height: 320px !important;
//   }
  
//   .swiper-slide {
//     display: flex !important;
//     align-items: center !important;
//     justify-content: center !important;
//     border-radius: 18px !important;
//     font-size: 22px !important;
//     font-weight: bold !important;
//     color: #fff !important;
//   }
//   .swiper-3d .swiper-slide-shadow {
//     background: rgba(0,0,0,.15);
//     width: 33% !important;
//     margin: 0 auto !important;
//     right: 0 !important;
//   } 
// }

.home-standout-text-wrapper {
  .home-standout-text {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 2.4rem;
  }
}

a.talent-card {
  position: relative;
  margin-bottom: 1.2rem;
  h4 {
    transition: all 200ms ease;
    letter-spacing: normal;
    svg {
      opacity: 0;
      margin-left: 8px;
      transition-delay: 50ms;
      transition: all 200ms ease;
    }
  }
  img.talent-card-image {
    transform: skew(0deg);
    object-fit: cover;
    height: 100%;
    width: 100%;
    max-height: 300px;
    aspect-ratio: 1;
    position: relative;
    z-index: 1;
    display: block;
    margin-bottom: 12px;
    transition: all 200ms ease;
    box-shadow: rgba(0,0,0,0) 1px 1px 0px 1px;
  }
  &:hover, &:focus-within {
    h4, p {
      color: var(--primary) !important;
      letter-spacing: 0.10px;
      svg {
        opacity: 1;
        margin-left: 24px;
      }
    }
    img {
      transform: skew(1deg);
      box-shadow: var(--primary-hover) 3px 3px 0px 1px;
    }
  }
}

.featured-img-100 {
  img {
    min-height: 450px;
    object-fit: contain !important;
  }
}

.texture-background {
  &:after {
    content:'';
    position: absolute;
    z-index: -1;
    inset: -100px 0 0 0;
    width: 50vw;
    background-image: radial-gradient(var(--surface-900) 10%, transparent 20%),
    radial-gradient(transparent 210%, transparent 20%);
    background-position: fixed;
    background-size: 20px 20px;
    filter: opacity(0.1);
    height: 1000vh;
  }
  &.texture-right {
    &:after {
      right: 0;
      left: initial;
    }
  }
}