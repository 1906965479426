.date-format-badge {
  position: absolute;
  inset: 20px 20px auto auto;
  background-color: var(--white);
  aspect-ratio: 1;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-width: 60px;
  min-height: 60px;
  span {
    text-transform: uppercase;
    color: var(--primary);
    padding: 0;
    margin: 0;
    &.date-format-badge-month {
      margin-bottom: -6px;
      font-size: 12px;
    }
    &.date-format-badge-date {
      margin-bottom: -6px;
      font-weight: bold;
      font-size: 16px;
    }
  }
}

.event-slider-wrapper {
  .swiper {
    width: 100% !important;
    padding-top: 0px !important;
    padding-bottom: 100px !important;
  }
  
  .swiper-slide {
    background-position: center !important;
    background-size: cover !important;
    width: 250px !important;
    height: 300px !important;
    @media only screen and (min-width: 960px) {
      width: 300px !important;
      height: 350px !important;
    }
  }
  
  .swiper-slide {
    a {
      display: block !important;
      width: 100% !important;
      height: 100% !important;
      // max-width: 250px;
      // @media only screen and (min-width: 960px) {
      //   max-width: 300px;
      // }
    }
    img {
      display: block !important;
      width: 100% !important;
      height: 100%;
      object-fit: cover;
    }
  }
}
